<template>

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="breadcrumb-hero">
        <div class="container">
          <div class="breadcrumb-hero">
            <h2>Roadbases</h2>
          </div>
        </div>
      </div>
      <div class="container">
        <ol>
          <li><router-link :to="{ name: 'home' }">Home</router-link></li>
          <li>Products</li>
          <li>Roadbases</li>
        </ol>
      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Product Section ======= -->
    <section id="products" class="product">
      <div class="container">

        <div class="row">
          <product>
            <template v-slot:img>
              <a href="/img/roadbases/Type_2.1.jpg" target="_blank">
                <img src="@/assets/img/roadbases/th_Type_2.1.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              Type 2.1 & 2.2
            </template>
            <template v-slot:description>
              Roadbase made from the freshest blue rock. Colour varies from almost black when wet to bright grey when dry.
              This product is blended through our pugmill plant to ensure a consistently high quality product is produced.<br/>
              <br/>
              Also available as a Cement Treated Roadbase or CTB with cement contents from 2 to 5% or more available.
              Notice is required for cement treated products as they are produced at time of despatch from the quarry.<br/>
              <br/>
              <b>Standards</b><br/>
              MRS04<br/>
              MRTS04<br/>
              <br/>
              <b>Applications</b><br/>
              Highway top course Roadbase.
            </template>
          </product>

          <product>
            <template v-slot:img>
              <a href="/img/roadbases/Type_2.3.jpg" target="_blank">
                <img src="@/assets/img/roadbases/th_Type_2.3.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              Type 2.3 & 2.4
            </template>
            <template v-slot:description>
              Still blended utilising the same processes as Type 2.1 and to the same high standard. The main difference when compared to Type 2.1 is its manufacture from more weathered rock.
              Colour is typically browner than Type 2.1 & 2.2 and has more plasticity making it easier to work and compact than its higher grade equivalent.<br/>
              <br/>
              Please note that Mt Marrow no longer uses the CBR classification system for its products, however this product meets and exceeds CBR 80 specification.<br/>
              <br/>
              <b>Standards</b><br/>
              MRS04<br/>
              MRTS04<br/>
              <br/>
              <b>Applications</b><br/>
              Lower course Roadbase, High quality driveway Roadbase.
            </template>
          </product>

          <product>
            <template v-slot:img>
              <!-- needs Image -->
            </template>
            <template v-slot:name>
              Type 2.5
            </template>
            <template v-slot:description>
              Available in both 19mm 'C' grading and the larger 37mm 'B' grading.
              This product is considerably browner than Type 2.3 and is produced by loader mixing rather than via the pugmill.
              This still results in a well blended and consistent product backed by the same quality assurance processes as higher grade products,
              however little moisture is able to be added during the mixing process.<br/>
              <br/>
              The 37mm version of this product is recommended where the site conditions may be wet, boggy or has heavy clay present as the larger stone is
              better able to resist loads under these conditions.<br/>
              <br/>
              <b>Standards</b><br/>
              MRS04<br/>
              MRTS04<br/>
              <br/>
              <b>Applications</b><br/>
              Bottom course Roadbase, Driveways, Construction Bedding & Pads.
            </template>
          </product>

          <product>
            <template v-slot:img>
              <a href="/img/roadbases/19mm_Scalps.jpg" target="_blank">
                <img src="@/assets/img/roadbases/th_19mm_Scalps.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              19mm Scalps
            </template>
            <template v-slot:description>
              Produced via a process called 'Scalping' where fines contained within the source rock delivered to the crushing
              plant are removed before the rock enters the first stage of crushing. These removed fines are then screened and delivered to stockpile via a conveyor.
              The result is a roadbase-like product known as Scalps.<br/>
              <br/>
              19mm Scalps is produced as a 19mm down to fines material. It typically contains quite sharp and angular stones,
              less fines compared with blended roadbases and less moisture also. It is ideal as a low cost alternative to our blended roadbase products.
              It is supplied 'as-is' however the quarry will endeavor to supply scalps with a good ratio of rock to fines.<br/>
              <br/>
              Please note that the colour of scalps can vary even on an hour-to-hour basis depending on where the source rock is being hauled from in the quarry.
              As a result even two loads delivered on the same day may result in very different colour material. If colour is important for your application please
              discuss this with the quarry as it may be necessary to utilise a higher grade roadbase to ensure some level of consistency.<br/>
              <br/>
              <b>Standards</b><br/>
              None<br/>
              <br/>
              <b>Applications</b><br/>
              Driveways, Building sites, Concrete pads and other applications where a certified blended roadbase is not required.
            </template>
          </product>

          <product>
            <template v-slot:img>
              <a href="/img/roadbases/37mm_Scalps.jpg" target="_blank">
                <img src="@/assets/img/roadbases/th_37mm_Scalps.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              37mm Scalps
            </template>
            <template v-slot:description>
              37mm Scalps consists of a rock top size of 37mm down to dust. Unlike our 19mm Scalps,
              this version of our scalps product is deliberately produced via mobile crushing plant rather than via the scalping process.
              As a result it contains more fines and is of a more consistent colour than the 19mm version.<br/>
              <br/>
              The larger stone size is better able to handle wet, boggy and heavy clay conditions than our smaller sized roadbase products.<br/>
              <br/>
              <b>Standards</b><br/>
              None<br/>
              <br/>
              <b>Applications</b><br/>
              Driveways, Building sites, Concrete pads, Temporary Roads.
            </template>
          </product>

          <product>
            <template v-slot:img>
              <a href="/img/roadbases/70mm_Scalps.jpg" target="_blank">
                <img src="@/assets/img/roadbases/th_70mm_Scalps.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              70mm Scalps
            </template>
            <template v-slot:description>
              If 37mm Scalps is not effective enough in particularly heavy clay areas or very boggy ground then this is the next step up.
              With a 70mm top size down to fines this product is manufactured either by loader blending larger rock with 37mm Scalps or purpose made
              through a mobile crushing plant depending on the volume required.<br/>
              <br/>
              Due to the larger top size we don't recommend this product for driveway applications unless earthmoving equipment is available to work and lay the material.<br/>
              <br/>
              <b>Standards</b><br/>
              None<br/>
              <br/>
              <b>Applications</b><br/>
              Heavy clay or bog areas.
            </template>
          </product>

        </div>

      </div>
    </section><!-- End Product Section -->
  </main>
</template>
<script>
import Product from '@/components/Product.vue';

export default {
  components: {
    Product,
  },
};
</script>
